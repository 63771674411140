export function wrapper() {
  return import(/* webpackChunkName: "wrapper" */ './wrapper');
}

export function login() {
  return import(/* webpackChunkName: "login" */ './login');
}

export function createAccount() {
  return import(/* webpackChunkName: "create-account" */ './create-account');
}

export function dashboard() {
  return import(/* webpackChunkName: "dashboard" */ './dashboard');
}

export function resetPassword() {
  return import(/* webpackChunkName: "reset-password" */ './reset-password');
}

export function givingHistory() {
  return import(/* webpackChunkName: "giving-history" */ './giving-history');
}

export function myConnections() {
  return import(/* webpackChunkName: "my-connections" */ './my-connections');
}

export function bpwidgets() {
  return import(/* webpackChunkName: "bpwidgets" */ './bpwidgets');
}

export function memberDirectory() {
  return import(/* webpackChunkName: "member-directory" */ './member-directory');
}